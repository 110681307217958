// * {
//   border: 1px solid red;
// }

$base-color: #3d3c42;
.row {
  padding: 0 0;
  margin: 0 0;
}
.card {
  height: 484px;

  margin: 1px 0 37px 0;
  background-color: #111722;
  border: none;
  box-shadow: 3px 4px 13px 0px #3d3c42;
  text-transform: capitalize;

  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
  }
  .img-container {
    img {
      height: 254px;
    }
  }
  .card-title {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
  }

  .card-text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      margin: 0 8px 0px 0px;
      background-color: rgb(217 217 217 / 11%);
      color: white;
    }
  }

  .card-buttons {
    a {
      width: 32%;
      color: white;
      font-size: 16px;
      font-weight: 500;
      font-family: monospace;
      padding: 6px 0;

      border: 1px solid #fff;
      margin: 0 8px;

      &:hover {
        background-color: white;
        color: $base-color;
        // border: 2px solid $base-color;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
