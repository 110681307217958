.row {
  padding: 0 0;
  margin: 0 0;
}

.header .row {
  height: 100vh;
}
.header .row .col-lg-6:first-child {
  position: relative;
}
.header .row .col-lg-6:first-child img {
  width: 58%;
  position: absolute;
  top: 17%;
  left: 31%;
}
@media screen and (max-width: 768px) {
  .header .row .col-lg-6:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    position: static;
  }
  .header .row .col-lg-6:first-child img {
    position: static;
    width: 88%;
    margin: 30px 0 0 0;
  }
}
.header .row .col-lg-6:nth-child(2) {
  display: flex;
}
.header .header-text {
  color: #fff;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .header .header-text {
    padding: 0 0 0 0;
  }
}
.header .header-text h4 {
  font-size: 45px;
  font-weight: 600;
  font-family: inherit;
}
.header .header-text p {
  width: 86%;
  font-size: 15px;
  line-height: 32px;
}
.header .header-text h2 {
  margin: 14px 0px 5px 0;
}
.header .header-text .header-buttons a {
  border: 1px solid #fff;
  width: 94px;
  font-size: 18px;
  font-weight: 600;
}
.header .header-text .header-buttons a:first-child {
  margin-right: 10px;
  background-color: #fff;
}
.header .header-text .header-buttons a:last-child {
  margin-left: 10px;
  color: #fff;
  width: 150px;
}
.header .header-text .list-unstyled {
  margin: 19px 0 0 0;
  display: flex;
  justify-self: center;
  align-items: center;
}
.header .header-text .list-unstyled li {
  display: inline;
  padding: 0 4px;
}
.header .header-text .list-unstyled li a {
  color: #fff;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
  font-size: 27px;
}
.header .header-text .list-unstyled li a:hover {
  color: #fff;
}/*# sourceMappingURL=style.css.map */