// * {
//   border: 1px solid red;
//   box-sizing: border-box;
// }

.row {
  padding: 0 0;
  margin: 0 0;
}

@mixin heading {
  font-size: 45px;
  font-weight: 600;
  font-family: inherit;
}

.header {
  .row {
    height: 100vh;
    .col-lg-6 {
      &:first-child {
        position: relative;
        img {
          width: 58%;
          position: absolute;
          top: 17%;
          left: 31%;
        }

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          img {
            position: static;
            width: 88%;
            margin: 30px 0 0 0;
          }
          position: static;
        }
      }

      &:nth-child(2) {
        display: flex;
      }
    }
  }

  .header-text {
    color: #fff;
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

    @media screen and (max-width: 767px) {
      padding: 0 0 0 0;
    }

    h4 {
      @include heading;
    }

    p {
      width: 86%;
      font-size: 15px;
      line-height: 32px;
    }

    h2 {
      margin: 14px 0px 5px 0;
    }

    .header-buttons {
      a {
        border: 1px solid #fff;
        width: 94px;
        font-size: 18px;
        font-weight: 600;
      }

      a:first-child {
        margin-right: 10px;
        background-color: #fff;
      }

      a:last-child {
        margin-left: 10px;
        color: #fff;
        width: 150px;
      }
    }

    .list-unstyled {
      margin: 19px 0 0 0;
      display: flex;
      justify-self: center;
      align-items: center;
      li {
        display: inline;
        padding: 0 4px;

        a {
          color: #fff;
          font-size: 20px;
          margin: 0px 10px 0px 0px;
          font-size: 27px;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
